
@import url("windows-default.css");

%checkerboard {
	background: var(--checker) repeat;
	image-rendering: pixelated;
}
%font {
	font-family: 'Segoe UI', sans-serif;
	font-size: 12px;
}
%button-face {
	background-color: var(--ButtonFace);
	color: var(--ButtonText);
}
%inset-shallow {
	border-style: solid;
	border-width: 1px;
	border-color: var(--ButtonShadow) var(--ButtonHilight) var(--ButtonHilight) var(--ButtonShadow);
	@extend %button-face;
}
%outset-shallow {
	border-style: solid;
	border-width: 1px;
	border-color: var(--ButtonHilight) var(--ButtonShadow) var(--ButtonShadow) var(--ButtonHilight);
	@extend %button-face;
}
%inset-deep {
	@extend %inset-shallow; /* as fallback */
	position: relative; /* for pseudo element(s) */
	border-image: var(--inset-deep-border-image);
}
%outset-deep {
	@extend %outset-shallow; /* as fallback */
	position: relative; /* for pseudo element(s) */
	border-image: var(--button-normal-border-image);
}
%outset-deep {
	border-color: var(--ButtonLight) var(--ButtonShadow) var(--ButtonShadow) var(--ButtonLight);
	border-style: solid;
	border-width: 2px 2px;
}
%inset-deep {
	border-color: var(--ButtonShadow);
	border-style: solid;
	border-width: 2px 2px;
}

%outset-deep-2 {
	background-color: var(--ButtonFace);
	border-top: 1px solid var(--ButtonFace);
	border-left: 1px solid var(--ButtonFace);
	border-right: 1px solid var(--ButtonDkShadow);
	border-bottom: 1px solid var(--ButtonDkShadow);
	box-shadow: 1px 1px 0 var(--ButtonHilight) inset, -1px -1px 0 var(--ButtonShadow) inset;
}

.inset-deep {
	@extend %inset-deep;
}
.outset-deep {
	@extend %outset-deep;
}
.inset-shallow {
	@extend %inset-shallow;
}
.outset-shallow {
	@extend %outset-shallow;
}

button {
	@extend %button-face;
}
button:not(.lightweight) {
	outline: none; /* replaced with inner dotted focus ring (which is admittedly not so prominent...) */
	@extend %outset-deep;
	border-image: var(--button-normal-border-image);
}
button:not(.lightweight).default {
	border-image: var(--button-default-border-image);
}
button:not(.lightweight):hover:active {
	border-image: var(--button-active-border-image);
}
button:not(.lightweight).default:hover:active {
	border-image: var(--button-default-active-border-image);
}

/* TODO: offset content on press! */
/* (this would need a child ELEMENT, wouldn't work with text nodes as direct descendants:) */
/*button:active > * {
	position: relative;
	left: 1px;
	top: 1px;
}
*/
/* also, this is more complicated; see Paint; the tool buttons translate when being pushed and when depressed, and these add together */

button:not(.lightweight):focus::before {
	content: "";
	display: block;
	position: absolute;
	left: 2px;
	top: 2px;
	right: 2px;
	bottom: 2px;
	border: 1px dotted var(--ButtonDkShadow); /* ?? */
	/* TODO: get exact inset dimensions, and have corners without dots ideally */
	/* (could use outline instead of ::before at this point) */
}
button.lightweight {
	outline: none; /* these buttons are not usually keyboard accessible, or the focus it shown just by inset (menu buttons) */

	border-style: solid;
	border-width: 1px;
	border-color: transparent;
}
/* TODO: recommend preventing focus on click for lightweight buttons */
button.lightweight:not([disabled]):hover /*,
button.lightweight:focus */ {
	@extend %outset-shallow;
}
button.lightweight:not([disabled]):hover:active {
	@extend %inset-shallow;
}
button[disabled] {
	color: var(--GrayText);
	text-shadow: 1px 1px 0px var(--ButtonHilight);
}
/* TODO: [checked]? .pressed? .depressed? .down? .held? what should it be called? */
button:not(.lightweight).toggle:hover:active {
	@extend %inset-deep;
}
button:not(.lightweight).toggle.selected {
	@extend %inset-deep;
	@extend %checkerboard;
}

.os-window .window-titlebar,
body > .window-titlebar {
	background: var(--ActiveTitle);
	background: linear-gradient(to right, var(--ActiveTitle) 0%, var(--GradientActiveTitle) 100%);
	
	color: var(--TitleText);
	@extend %font;
	font-weight: bold;
}
.os-window:not(.focused) .window-titlebar {
	background: darkgray;
	background: linear-gradient(to right, var(--InactiveTitle) 0%, var(--GradientInactiveTitle) 100%);

	color: var(--InactiveTitleText);
}
.os-window {
	/* Needed for when maximized, otherwise would be provided by %outset-deep */
	background: var(--ButtonFace);

	/* background: var(--Window); */
	/*color: var(--WindowText);*/
	/*border: 1px solid var(--WindowFrame);*/
	/* TODO: use window-specific theme colors; also different types of windows */
	/* padding: 2px; */
}
.os-window:not(.maximized) {
	@extend %outset-deep;
}
.os-window > * {
	/* margin: 1px; */
}
.window-button {
	margin: 2px 0;
}
.window-close-button {
	margin-left: 2px;
	margin-right: 2px;
}
.os-window .window-title-area {
	height: 16px; /* 100% doesn't work */
}
.os-window .window-title {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: inline-block !important;
	margin: 0;
	padding: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	padding-left: 2px;
}
.window-close-button {
	/* display: block !important; */
	/* float: right; */
	/* width: 13px;
	height: 11px; */
}
.window-close-button,
.window-maximize-button,
.window-minimize-button {
	background-image: url("images/titlebar-buttons.png");
	image-rendering: pixelated;
	display: block;
	width: 16px;
	height: 14px;
	padding: 0;
}

.window-close-button {
	background-position: calc(-3 * 13px - 1px) 0px;
}
.window-maximize-button {
	background-position: calc(-1 * 13px - 1px) 0px;
}
.maximized .window-maximize-button {
	background-position: calc(-2 * 13px - 1px) 0px;
}
.window-minimize-button {
	background-position: calc(-0 * 13px - 1px) 0px;
}

.window-close-button:hover:active {
	background-position: calc(-3 * 13px - 0px) 1px;
}
.window-maximize-button:hover:active {
	background-position: calc(-1 * 13px - 0px) 1px;
}
.maximized .window-maximize-button:hover:active {
	background-position: calc(-2 * 13px - 0px) 1px;
}
.window-minimize-button:hover:active {
	background-position: calc(-0 * 13px - 0px) 1px;
}


.menus {
	background: var(--Menu);
	color: var(--MenuText);
}
.os-window:not(.focused) .menus {
	color: var(--GrayText);
}
.menus *,
.menu-popup * {
	@extend %font;
}
.menu-container {
	margin-bottom: 1px;
}
.menu-button {
	box-sizing: border-box;
	height: 18px;
	line-height: 1;
	margin-top: 1px;
	margin-bottom: 1px;
	padding: 2px 5px;
	position: relative;
	outline: 0;
	/* @extend button.lightweight; */
	background: var(--Menu);
}
.menu-button:hover {
	box-shadow: 1px 1px 0 var(--ButtonHilight) inset, -1px -1px 0 var(--ButtonShadow) inset;
}
.menu-button:active,
.menu-button.active {
	box-shadow: 1px 1px 0 var(--ButtonShadow) inset, -1px -1px 0 var(--ButtonHilight) inset;
	top: 1px;
	left: 1px;
}
.menu-popup {
	display: block;
	padding: 2px;
	@extend %outset-deep-2;
	background: var(--Menu);
	color: var(--MenuText);
}
.menu-item {
	padding: 1px 3px;
	margin: 2px;
}
.menu-item[disabled] {
	color: var(--GrayText);
	text-shadow: 0.8px 0.8px 0px var(--ButtonHilight);
}
.menu-item:focus,
.menu-item.active {
	background: var(--Hilight);
	color: var(--HilightText);
	text-shadow: none;
	outline: 0;
}
.menu-item .menu-item-shortcut {
	padding-left: 10px;
}
.menu-hr {
	border: 0;
	border-top: 1px solid var(--ButtonShadow);
	border-bottom: 1px solid var(--ButtonHilight);
	margin: 0px 2px;
}
.menu-hotkey {
	text-decoration: underline;
}


::selection {
	background-color: var(--Hilight);
	color: var(--HilightText);
}

.scrollbar {
	@extend %checkerboard;
}
.scrollbar-thumb {
	@extend %outset-deep-2;
}
.scrollbar-track-piece:hover:active {
	@extend %checkerboard;
	background-color: white;
	background-blend-mode: difference;
	/* background-attachment: fixed; breaks the checkered background in chrome */
}
.scrollbar-track-piece.increment {
	background-position: bottom;
}
.scrollbar-corner {
	background-color: var(--ButtonFace);
}

.scrollbar-button {
	@extend %outset-deep-2;
	background-image: var(--scrollbar-arrows-ButtonText);
	image-rendering: pixelated;
	width: var(--scrollbar-size);
	height: var(--scrollbar-size);
	box-sizing: border-box;
}
.scrollbar-button:not(.disabled):hover:active {
	border: 1px solid var(--ButtonShadow);
	box-shadow: none;
}
.scrollbar-button.disabled {
	background-image: var(--scrollbar-arrows-GrayText), var(--scrollbar-arrows-ButtonHilight);
}

.scrollbar-button.horizontal.decrement.disabled {
	background-position: /* left arrow */
		calc(var(--scrollbar-button-inner-size) * -3 + 1px) 1px,
		calc(var(--scrollbar-button-inner-size) * -3 + 2px) 2px;
}
.scrollbar-button.horizontal.increment.disabled {
	background-position: /* right arrow */
		calc(var(--scrollbar-button-inner-size) * -2 + 1px) 1px,
		calc(var(--scrollbar-button-inner-size) * -2 + 2px) 2px;
}
.scrollbar-button.vertical.decrement.disabled {
	background-position: /* up arrow */
		calc(var(--scrollbar-button-inner-size) * -1 + 1px) 1px,
		calc(var(--scrollbar-button-inner-size) * -1 + 2px) 2px;
}
.scrollbar-button.vertical.increment.disabled {
	background-position: /* down arrow */
		calc(var(--scrollbar-button-inner-size) * -0 + 1px) 1px,
		calc(var(--scrollbar-button-inner-size) * -0 + 2px) 2px;
}

.scrollbar-button.horizontal.decrement {
	background-position: calc(var(--scrollbar-button-inner-size) * -3 + 1px) 1px; /* left */
}
.scrollbar-button.horizontal.increment {
	background-position: calc(var(--scrollbar-button-inner-size) * -2 + 1px) 1px; /* right */
}
.scrollbar-button.vertical.decrement {
	background-position: calc(var(--scrollbar-button-inner-size) * -1 + 1px) 1px; /* up */
}
.scrollbar-button.vertical.increment {
	background-position: calc(var(--scrollbar-button-inner-size) * -0 + 1px) 1px; /* down */
}

::-webkit-scrollbar,
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-button {
	width: var(--scrollbar-size);
	height: var(--scrollbar-size);
}

::-webkit-scrollbar {
	@extend .scrollbar;
}
::-webkit-scrollbar-thumb {
	@extend .scrollbar-thumb;
}
::-webkit-scrollbar-corner {
	@extend .scrollbar-corner;
}

::-webkit-scrollbar-button {
	@extend .scrollbar-button;
}
::-webkit-scrollbar-button:hover:active {
	@extend .scrollbar-button:not(.disabled):hover:active;
}
::-webkit-scrollbar-button:disabled {
	@extend .scrollbar-button.disabled;
}

::-webkit-scrollbar-button:horizontal:decrement:disabled {
	@extend .scrollbar-button.horizontal.decrement.disabled;
}
::-webkit-scrollbar-button:horizontal:increment:disabled {
	@extend .scrollbar-button.horizontal.increment.disabled;
}
::-webkit-scrollbar-button:vertical:decrement:disabled {
	@extend .scrollbar-button.vertical.decrement.disabled;
}
::-webkit-scrollbar-button:vertical:increment:disabled {
	@extend .scrollbar-button.vertical.increment.disabled;
}

::-webkit-scrollbar-button:horizontal:decrement {
	@extend .scrollbar-button.horizontal.decrement;
}
::-webkit-scrollbar-button:horizontal:increment {
	@extend .scrollbar-button.horizontal.increment;
}
::-webkit-scrollbar-button:vertical:decrement {
	@extend .scrollbar-button.vertical.decrement;
}
::-webkit-scrollbar-button:vertical:increment {
	@extend .scrollbar-button.vertical.increment;
}

::-webkit-scrollbar-track-piece {
	@extend .scrollbar-track-piece;
}
::-webkit-scrollbar-track-piece:increment {
	@extend .scrollbar-track-piece.increment;
}

/* turn off double buttons */
::-webkit-scrollbar-button:start:increment,
::-webkit-scrollbar-button:end:decrement {
	display: none;
}
